import React from 'react'
import { IconBookmark, IconArrowDown } from '../icons'
import FloatingLabel from '../form/floatingLabel'
import Link from 'next/link'
import Strings from '../strings'

const onInput = onChange => {
  return e => {
    // Dispatch the onChange action with the new value
    onChange({
      value: e.target.value,
    })
  }
}

const Intro = props => {
  const {
    state: { value },
    readOnly,
    onChange,
  } = props

  // If readOnly is false, it means that we are in edit mode!
  if (!readOnly) {
    return (
      <div className="py=6 flex justify-center align-center background mb=3 w=3/4">
        {/* <Background className="absolute intro__content"/> */}
        <FloatingLabel
          name="introtext"
          type="text"
          onChange={onInput(onChange)}
          value={value}
          placeholder="Introtext"
          notFormik
        />
        {/* <input className="head-12 w=3/4 mx text-align=c"  /> */}
      </div>
    )
  }

  // If we are not in edit mode, remove the input field
  return (
    <div className="py=6 flex justify-center align-center background w=viewport cm--intro">
      <Background className="absolute intro__content" />
      <div className="w=site mx intro__content">
        <h1 className="text-align=c">{value}</h1>
        <div className="flex justify-center align-center mt=3">
          <Link href="/[slug]" as="/checks">
            <a className="r-circle py=1 px=2 c=prim mx=1 cm--btn-primary">{Strings.de.components.cms.purchase}</a>
          </Link>
        </div>
      </div>
      <IconArrowDown className="absolute arrowdown pointer" />
      <style jsx>{`
        .background {
          overflow: hidden;
          height: calc(100vh - 12rem);
          background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(102, 109, 105, 0.06) 100%);
        }
        .intro__content {
          transform: translateY(-6rem);
        }
      `}</style>
    </div>
  )
}

export default {
  Component: Intro,
  IconComponent: <IconBookmark />,
  name: 'example/content/input-text-field',
  version: '0.0.1',
  text: 'Intro',
}

const Background = props => (
  <svg {...props} width="946px" height="376px" viewBox="0 0 946 376" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-126.000000, -229.000000)" stroke="#D9DBDA">
        <g transform="translate(0.000000, 45.000000)">
          <g transform="translate(127.000000, 185.000000)">
            <path
              d="M346.348269,0 L337,9.29825968 L337,29.6554193 C337,37.958365 342.576298,40.3686881 352.713715,46.635528 C353.498943,47.1214907 354.49975,47.1214907 355.286285,46.635528 C365.423702,40.3686881 371,37.958365 371,29.6554193 L371,1.60991388 C371,0.721147865 370.274872,0 369.381201,0 L346.348269,0 Z"
              id="Fill-26"
            ></path>
            <path
              d="M345.020505,23.4642034 L349.451015,29.9076837 C349.496116,29.9963184 349.607542,30.0272063 349.691112,29.9734883 L363.919153,17.3175153 C364.092924,17.2073933 363.960274,16.938803 363.769258,17.0126654 L349.571727,25.1469213 L345.238051,23.2291869 C345.092136,23.1633823 344.944894,23.3218505 345.020505,23.4642034"
              id="Fill-29"
              strokeOpacity="0.75"
            ></path>
            <path
              d="M106.348269,197 L97,206.29826 L97,226.655419 C97,234.958365 102.576298,237.368688 112.713715,243.635528 C113.498943,244.121491 114.49975,244.121491 115.286285,243.635528 C125.423702,237.368688 131,234.958365 131,226.655419 L131,198.609914 C131,197.721148 130.274872,197 129.381201,197 L106.348269,197 Z"
              id="Fill-26"
            ></path>
            <path
              d="M40.1486762,119 L33,126.319907 L33,142.345756 C33,148.882117 37.2642278,150.779605 45.0163701,155.713075 C45.616839,156.095642 46.3821619,156.095642 46.9836299,155.713075 C54.7357722,150.779605 59,148.882117 59,142.345756 L59,120.267379 C59,119.567712 58.4454905,119 57.7620951,119 L40.1486762,119 Z"
              id="Fill-26-Copy"
            ></path>
            <path
              d="M572.148676,320 L565,327.319907 L565,343.345756 C565,349.882117 569.264228,351.779605 577.01637,356.713075 C577.616839,357.095642 578.382162,357.095642 578.98363,356.713075 C586.735772,351.779605 591,349.882117 591,343.345756 L591,321.267379 C591,320.567712 590.445491,320 589.762095,320 L572.148676,320 Z"
              id="Fill-26-Copy-4"
            ></path>
            <path
              d="M4.9490835,232 L0,236.945883 L0,247.774159 C0,252.19062 2.95215771,253.472706 8.31902548,256.806132 C8.73473466,257.064623 9.26457365,257.064623 9.68097452,256.806132 C15.0478423,253.472706 18,252.19062 18,247.774159 L18,232.856337 C18,232.383589 17.6161088,232 17.1429889,232 L4.9490835,232 Z"
              id="Fill-26-Copy-2"
            ></path>
            <path
              d="M105.020505,220.464203 L109.451015,226.907684 C109.496116,226.996318 109.607542,227.027206 109.691112,226.973488 L123.919153,214.317515 C124.092924,214.207393 123.960274,213.938803 123.769258,214.012665 L109.571727,222.146921 L105.238051,220.229187 C105.092136,220.163382 104.944894,220.321851 105.020505,220.464203"
              id="Fill-29"
            ></path>
            <path
              d="M39.0151088,137.469711 L42.2796955,142.921886 C42.3129278,142.996885 42.3950312,143.023021 42.4566087,142.977567 L52.9404283,132.268667 C53.0684705,132.175487 52.9707284,131.948218 52.8299797,132.010717 L42.3686408,138.893549 L39.1754058,137.27085 C39.0678895,137.21517 38.9593958,137.349258 39.0151088,137.469711"
              id="Fill-29-Copy"
            ></path>
            <path
              d="M571.015109,338.469711 L574.279696,343.921886 C574.312928,343.996885 574.395031,344.023021 574.456609,343.977567 L584.940428,333.268667 C585.06847,333.175487 584.970728,332.948218 584.82998,333.010717 L574.368641,339.893549 L571.175406,338.27085 C571.06789,338.21517 570.959396,338.349258 571.015109,338.469711"
              id="Fill-29-Copy-4"
            ></path>
            <path
              d="M4.01079197,244.480725 L6.34263964,247.950291 C6.36637702,247.998018 6.42502229,248.01465 6.46900624,247.985724 L13.9574488,241.17097 C14.0489075,241.111673 13.9790917,240.967048 13.8785569,241.00682 L6.40617202,245.386804 L4.12528988,244.354178 C4.04849251,244.318744 3.97099697,244.404073 4.01079197,244.480725"
              id="Fill-29-Copy-2"
            ></path>
            <path
              d="M380.348269,327 L371,336.29826 L371,356.655419 C371,364.958365 376.576298,367.368688 386.713715,373.635528 C387.498943,374.121491 388.49975,374.121491 389.286285,373.635528 C399.423702,367.368688 405,364.958365 405,356.655419 L405,328.609914 C405,327.721148 404.274872,327 403.381201,327 L380.348269,327 Z"
              id="Fill-26"
            ></path>
            <path
              d="M929.498982,128 L924,133.341553 L924,145.036092 C924,149.805869 927.280175,151.190523 933.243362,154.790622 C933.705261,155.069793 934.293971,155.069793 934.756638,154.790622 C940.719825,151.190523 944,149.805869 944,145.036092 L944,128.924844 C944,128.414276 943.573454,128 943.047765,128 L929.498982,128 Z"
              id="Fill-26-Copy-3"
            ></path>
            <path
              d="M379.020505,350.464203 L383.451015,356.907684 C383.496116,356.996318 383.607542,357.027206 383.691112,356.973488 L397.919153,344.317515 C398.092924,344.207393 397.960274,343.938803 397.769258,344.012665 L383.571727,352.146921 L379.238051,350.229187 C379.092136,350.163382 378.944894,350.321851 379.020505,350.464203"
              id="Fill-29"
              strokeOpacity="0.75"
            ></path>
            <path
              d="M929.010792,141.480725 L931.34264,144.950291 C931.366377,144.998018 931.425022,145.01465 931.469006,144.985724 L938.957449,138.17097 C939.048907,138.111673 938.979092,137.967048 938.878557,138.00682 L931.406172,142.386804 L929.12529,141.354178 C929.048493,141.318744 928.970997,141.404073 929.010792,141.480725"
              id="Fill-29-Copy-3"
            ></path>
            <path
              d="M214.922607,275 L202,287.66146 L202,315.381848 C202,326.687986 209.708412,329.970128 223.7219,338.503698 C224.807363,339.165434 226.190831,339.165434 227.2781,338.503698 C241.291588,329.970128 249,326.687986 249,315.381848 L249,277.192223 C249,275.981989 247.997617,275 246.762249,275 L214.922607,275 Z"
              id="Fill-26"
            ></path>
            <path
              d="M214.028059,306.453189 L220.090863,314.879279 C220.15258,314.995186 220.305058,315.035577 220.419416,314.965331 L239.889367,298.415212 C240.127159,298.271207 239.945638,297.919973 239.684248,298.016562 L220.256047,308.653666 L214.325754,306.14586 C214.126081,306.059808 213.924592,306.267035 214.028059,306.453189"
              id="Fill-29"
            ></path>
            <path
              d="M804.922607,62 L792,74.66146 L792,102.381848 C792,113.687986 799.708412,116.970128 813.7219,125.503698 C814.807363,126.165434 816.190831,126.165434 817.2781,125.503698 C831.291588,116.970128 839,113.687986 839,102.381848 L839,64.1922232 C839,62.9819886 837.997617,62 836.762249,62 L804.922607,62 Z"
              id="Fill-26"
            ></path>
            <path
              d="M804.028059,93.453189 L810.090863,101.879279 C810.15258,101.995186 810.305058,102.035577 810.419416,101.965331 L829.889367,85.4152123 C830.127159,85.2712066 829.945638,84.9199732 829.684248,85.0165624 L810.256047,95.6536664 L804.325754,93.1458598 C804.126081,93.0598076 803.924592,93.2670353 804.028059,93.453189"
              id="Fill-29"
            ></path>
            <path
              d="M139.971487,23 L121,41.5965194 L121,82.3108386 C121,98.91673 132.316605,103.737376 152.889598,116.271056 C154.48315,117.242981 156.514199,117.242981 158.110402,116.271056 C178.683395,103.737376 190,98.91673 190,82.3108386 L190,26.2198278 C190,24.4422957 188.528417,23 186.714791,23 L139.971487,23 Z"
              id="Fill-26"
            ></path>
            <path
              d="M140.04101,68.9284067 L148.902031,81.8153673 C148.992233,81.9926369 149.215085,82.0544126 149.382224,81.9469765 L177.838305,56.6350306 C178.185848,56.4147866 177.920548,55.8776061 177.538516,56.0253307 L149.143454,72.2938427 L140.476102,68.4583738 C140.184272,68.3267645 139.889788,68.643701 140.04101,68.9284067"
              id="Fill-29"
            ></path>
            <path
              d="M570.321792,7 L553,24.0138369 L553,61.2631077 C553,76.4557317 563.332552,80.8661101 582.116589,92.3330937 C583.571571,93.2223021 585.426008,93.2223021 586.883411,92.3330937 C605.667448,80.8661101 616,76.4557317 616,61.2631077 L616,9.94579987 C616,8.31954716 614.656381,7 613.000461,7 L570.321792,7 Z"
              id="Fill-26"
            ></path>
            <path
              d="M569.037772,48.9339139 L577.199239,60.8295698 C577.28232,60.9932033 577.487578,61.050227 577.641522,60.9510553 L603.851071,37.5861821 C604.171176,37.3828799 603.926821,36.887021 603.574949,37.0233822 L577.421602,52.0404701 L569.438515,48.5000373 C569.169724,48.3785519 568.898489,48.6711087 569.037772,48.9339139"
              id="Fill-29"
            ></path>
            <path
              d="M770.670061,203 L744,227.135908 L744,279.977897 C744,301.530224 759.90885,307.786807 788.830304,324.053924 C791.070515,325.315359 793.925758,325.315359 796.169696,324.053924 C825.09115,307.786807 841,301.530224 841,279.977897 L841,207.178925 C841,204.871916 838.931253,203 836.381662,203 L770.670061,203 Z"
              id="Fill-26"
            ></path>
            <path
              d="M770.057197,262.906378 L782.41599,279.758557 C782.541798,279.990371 782.852618,280.071155 783.085733,279.930662 L822.774479,246.830425 C823.25921,246.542413 822.889186,245.839946 822.356352,246.033125 L782.752712,267.307333 L770.664036,262.29172 C770.25701,262.119615 769.846284,262.534071 770.057197,262.906378"
              id="Fill-29"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
