import React from 'react'
import { IconBookmark } from '../icons'
import { useEffect, useState } from 'react'
import { getChecks, Check } from '../../utils/checks'
import { CheckTableUser } from '../check'
import { PaginatedApiResponse } from '../../utils/fetch'
import Link from 'next/link'
import Strings from '../strings'

const onInput = onChange => {
  return e => {
    // Dispatch the onChange action with the new value
    onChange({
      value: e.target.value,
    })
  }
}

const Teaser = props => {
  const [checks, setChecks] = useState<PaginatedApiResponse<Check>>({
    data: [],
    totalRecords: 0,
    page: 0,
    perPage: 0,
  })

  useEffect(() => {
    getChecks().then(data => {
      setChecks(data)
    })
  }, [])

  const {
    state: { value },
    readOnly,
    onChange,
  } = props

  // If readOnly is false, it means that we are in edit mode!
  if (!readOnly) {
    return <div className="py=6 flex justify-center align-center mb=3 w=3/4">{Strings.de.components.cms.teaser}</div>
  }

  // If we are not in edit mode, remove the input field
  return (
    <div className="pt=6 flex wrap justify-center align-center w=viewport light cm--check-teaser">
      <div className="w=site mx relative check-teaser">
        <h1 className="text-align=c mb=5">{Strings.de.components.cms.checkOverview}</h1>
        <div className="elevated">
          {!!checks.data.length && (
            <CheckTableUser
              page={checks.page}
              perPage={checks.perPage}
              totalRecords={checks.totalRecords}
              checks={checks.data}
              token=""
            />
          )}
        </div>
      </div>
      <div className="flex w=full justify-center align-center mt=3 relative ripped-background">
        <div className="w=site mx flex justify-center align-center p=6">
          <Link href="/checks" as="/[slug]">
            <a className="r-circle py=2 px=4 c=prim mx=1">{Strings.de.components.cms.allChecks}</a>
          </Link>
        </div>
      </div>

      <style jsx>{`
        .light {
          background-color: #f8f9f9;
        }
        .check-teaser:after {
          content: ' ';
          display: block;
          height: 62px;
          width: 100%;
          left: 0;
          bottom: 0;
          position: absolute;
          background-image: linear-gradient(180deg, rgba(252, 253, 253, 0) 0%, #f8f9f9 100%);
        }
        .ripped-background {
          background-color: #eff1f1;
          z-index: 2;
        }
        .ripped-background:before {
          content: ' ';
          display: block;
          position: absolute;
          width: 100%;
          height: 13px;
          top: -11px;
          left: 0;
          z-index: 1;
          background-repeat: repeat-x;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 19' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cfilter x='-.7%25' y='-61.5%25' width='101.3%25' height='223.1%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeOffset dx='1' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' in='shadowBlurOuter1' result='shadowMatrixOuter1'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23a)' transform='translate(0 3)' fill='%23EFF1F1' fill-rule='evenodd'%3E%3Cpath d='M8.5 0L17 13H0z'/%3E%3C/g%3E%3C/svg%3E");
        }
      `}</style>
    </div>
  )
}

export default {
  Component: Teaser,
  IconComponent: <IconBookmark />,
  name: 'taenzer-digital/check-teaser',
  version: '0.0.1',
  text: 'Assistent Teaser',
}
